import React, { useState } from "react";
import Heder from "../components/navigation-header";
import Footer from "../components/footer";
// import "../styling/pages.css";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";
import { navigate } from "gatsby";
import RigthtPage from "../components/rigtht-page";
import SearchBar from "../components/search-bar/ParentComponent";
import ComponentCss from "../styling/component.css";
import PagesCss from "../styling/pages.css";
import TemplateCss from "../styling/templates.css";
import SitemapCss from "../components/sitemap.css";
import CookieCss from "../components/CookieNotice.css";

const SearchResult = ({ location }) => {
  const { state } = location;
  const results = state && state.results ? state.results : [];
  const searchTerm = state && state.searchTerm ? state.searchTerm : "";

  // Set the number of cards per page and per row
  const cardsPerPage = 10;

  // Calculate the total number of pages
  const totalPages = Math.ceil(results.length / cardsPerPage);

  // Initialize the current page state
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate the index range for the current page
  const startIndex = (currentPage - 1) * cardsPerPage;
  const endIndex = startIndex + cardsPerPage;

  // Get the cards for the current page
  const currentCards = results.slice(startIndex, endIndex);

  // Function to handle "Previous" button click
  const handlePreviousClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else {
      // Reload the page when at the first page
      const refreshPage = () => {
        navigate(window.location.pathname + window.location.search);
      };
      refreshPage();
      window.location.reload();
    }
  };

  // Function to handle "Next" button click
  const handleNextClick = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  if (results.length === 0) {
    return (
      <div style={{ backgroundColor: "#F5F5F5" }}>
        <Helmet>
          <title>{`You searched for "${searchTerm}" - BPPL`}</title>
          <meta
            name="title"
            content={`You searched for "${searchTerm}" - BPPL`}
          />
          <meta name="description" content="Page not found - BPPL" />
          <link
            rel="canonical"
            href="https://www.bpplholdings.com/search-result/"
          />
          <link
            rel="preload"
            href="https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700;800&display=swap"
            as="style"
          />
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700;800&display=swap"
          />
          <link rel="preload" href={`${PagesCss}`} as="style" />
          <link rel="stylesheet" href={`${PagesCss}`} />
          <link rel="preload" href={`${ComponentCss}`} as="style" />
          <link rel="stylesheet" href={`${ComponentCss}`} />
          <link rel="preload" href={`${TemplateCss}`} as="style" />
          <link rel="stylesheet" href={`${TemplateCss}`} />
          <link rel="preload" href={`${SitemapCss}`} as="style" />
          <link rel="stylesheet" href={`${SitemapCss}`} />
          <link rel="preload" href={`${CookieCss}`} as="style" />
          <link rel="stylesheet" href={`${CookieCss}`} />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="article" />
          <meta
            property="og:title"
            content={`You searched for "${searchTerm}" - BPPL`}
          />
          <meta
            property="og:url"
            content="https://www.bpplholdings.com/search-result/"
          />
          <meta property="og:image" content="" />
        </Helmet>

        <Heder />

        <div className="pc">
          <center>
            <div className="store">
              <table
                style={{
                  width: "90%",
                  textAlign: "start",
                  marginTop: "150px",
                  marginBottom: "30px",
                }}
              >
                <tbody>
                  <tr>
                    <td style={{ width: "70%", verticalAlign: "top" }}>
                      <div style={{ backgroundColor: "#fff" }}>
                        <div>
                          <div style={{ padding: "3em" }}>
                            <h1 className="categories-topic">
                              Search results for: {searchTerm}
                            </h1>
                          </div>
                          <div
                            style={{
                              height: "3px",
                              backgroundColor: "#F5F5F5",
                              width: "100%",
                            }}
                          ></div>
                          <div style={{ padding: "3em" }}>
                            <p>
                              Sorry, but nothing matched your search terms.
                              Please try again with some different keywords.
                            </p>
                            <div style={{ width: "50%" }}>
                              <SearchBar />
                            </div>
                          </div>
                          <div
                            style={{
                              height: "3px",
                              backgroundColor: "#F5F5F5",
                              width: "100%",
                            }}
                          ></div>
                        </div>
                      </div>
                    </td>
                    <td style={{ width: "auto", verticalAlign: "top" }}>
                      <div style={{ marginLeft: "60px", marginRight: "60px" }}>
                        <RigthtPage />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </center>
        </div>

        <div className="mobile">
          <div
            style={{
              marginTop: "50px",
              marginLeft: "10px",
              marginRight: "10px",
              marginBottom: "20px",
            }}
          >
            <div style={{ backgroundColor: "#fff" }}>
              <div>
                <div style={{ padding: "2em" }}>
                  <h1 className="categories-topic">
                    Search results for: {searchTerm}
                  </h1>
                </div>
                <div
                  style={{
                    height: "3px",
                    backgroundColor: "#F5F5F5",
                    width: "100%",
                  }}
                ></div>
                <div style={{ padding: "2em" }}>
                  <p>
                    Sorry, but nothing matched your search terms. Please try
                    again with some different keywords.
                  </p>
                  <SearchBar />
                </div>
                <div
                  style={{
                    height: "3px",
                    backgroundColor: "#F5F5F5",
                    width: "100%",
                  }}
                ></div>
              </div>
            </div>
            <br />
            <div
              style={{
                marginLeft: "10px",
                marginRight: "10px",
                marginBottom: "30px",
              }}
            >
              <br />
              <RigthtPage />
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }

  return (
    <div style={{ backgroundColor: "#F5F5F5" }}>
      <Helmet>
        <title>{`You searched for "${searchTerm}" - BPPL`}</title>
        <meta
          name="title"
          content={`You searched for "${searchTerm}" - BPPL`}
        />
        <link
          rel="canonical"
          href="https://www.bpplholdings.com/search-result/"
        />
        <link
          rel="preload"
          href="https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700;800&display=swap"
          as="style"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700;800&display=swap"
        />
        <link rel="preload" href={`${PagesCss}`} as="style" />
        <link rel="stylesheet" href={`${PagesCss}`} />
        <link rel="preload" href={`${ComponentCss}`} as="style" />
        <link rel="stylesheet" href={`${ComponentCss}`} />
        <link rel="preload" href={`${TemplateCss}`} as="style" />
        <link rel="stylesheet" href={`${TemplateCss}`} />
        <link rel="preload" href={`${SitemapCss}`} as="style" />
        <link rel="stylesheet" href={`${SitemapCss}`} />
        <link rel="preload" href={`${CookieCss}`} as="style" />
        <link rel="stylesheet" href={`${CookieCss}`} />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content={`You searched for "${searchTerm}" - BPPL`}
        />
        <meta
          property="og:url"
          content="https://www.bpplholdings.com/search-result/"
        />
        <meta property="og:image" content="" />
      </Helmet>

      <Heder />

      <div className="pc">
        <center>
          <div className="store">
            <table
              style={{
                width: "90%",
                textAlign: "start",
                marginTop: "150px",
                marginBottom: "30px",
              }}
            >
              <tbody>
                <tr>
                  <td style={{ width: "70%", verticalAlign: "top" }}>
                    <div style={{ backgroundColor: "#fff" }}>
                      <div>
                        <div style={{ padding: "3em" }}>
                          <h1 className="categories-topic">
                            Search results for: {searchTerm}
                          </h1>
                        </div>
                        <div
                          style={{
                            height: "3px",
                            backgroundColor: "#F5F5F5",
                            width: "100%",
                          }}
                        ></div>
                        <div>
                          {currentCards.map((item) => (
                            <div className="store-card" key={item.readmore}>
                              <div style={{ padding: "3em" }}>
                                <Link
                                  to={`/${item.readmore}/`}
                                  className="tpoic-link"
                                >
                                  <img
                                    className="news-image"
                                    src={item?.aimage}
                                    alt="news-image"
                                  />
                                </Link>
                                <Link
                                  to={`/${item.readmore}/`}
                                  className="tpoic-link"
                                >
                                  <h1 className="categories-topic">
                                    {item?.topic}
                                  </h1>
                                </Link>

                                {item?.link2n && (
                                  <p className="categories-para">
                                    <a href={item?.link}>{item?.link1n}</a> /{" "}
                                    <a href={item?.link2}>{item?.link2n}</a> /{" "}
                                    <a href={item?.link3}>{item?.link3n}</a>
                                  </p>
                                )}

                                <p className="categories-para">{item?.para}</p>
                                <p className="categories-para">
                                  <Link to={`/${item.readmore}/`}>
                                    Read More »
                                  </Link>
                                </p>
                              </div>
                              <div
                                style={{
                                  height: "3px",
                                  backgroundColor: "#F5F5F5",
                                  width: "100%",
                                }}
                              ></div>
                            </div>
                          ))}
                        </div>
                        <div
                          style={{
                            height: "3px",
                            backgroundColor: "#F5F5F5",
                            width: "100%",
                          }}
                        ></div>
                      </div>
                    </div>

                    <br />
                    <div
                      style={{
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        backgroundColor:
                          totalPages > 1 ? "#fff" : "transparent",
                        width: "100%",
                      }}
                    >
                      <center>
                        {totalPages > 1 && (
                          <div>
                            <a
                              className={`link-button-search ${
                                currentPage === 1 ? "disabled" : ""
                              }`}
                              onClick={
                                currentPage !== 1
                                  ? handlePreviousClick
                                  : undefined
                              }
                            >
                              ← Previous Page
                            </a>
                            <span
                              className="page-info"
                              style={{ marginLeft: "20%", marginRight: "20%" }}
                            >
                              Page {currentPage} of {totalPages}
                            </span>
                            <a
                              className={`link-button-search ${
                                currentPage === totalPages ? "disabled" : ""
                              }`}
                              onClick={
                                currentPage !== totalPages
                                  ? handleNextClick
                                  : undefined
                              }
                            >
                              Next Page →
                            </a>
                          </div>
                        )}
                      </center>
                    </div>
                    <br />
                  </td>
                  <td style={{ width: "auto", verticalAlign: "top" }}>
                    <div style={{ marginLeft: "60px", marginRight: "60px" }}>
                      <RigthtPage />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </center>
      </div>

      <div className="mobile">
        <div
          style={{ marginLeft: "20px", marginRight: "20px", marginTop: "20px" }}
        >
          <div style={{ backgroundColor: "#fff" }}>
            <div>
              <div style={{ padding: "3em" }}>
                <h1 className="categories-topic">
                  Search results for: {searchTerm}
                </h1>
              </div>
              <div
                style={{
                  height: "3px",
                  backgroundColor: "#F5F5F5",
                  width: "100%",
                }}
              ></div>
              <div>
                {currentCards.map((item) => (
                  <div className="store-card" key={item.readmore}>
                    <div style={{ padding: "2em" }}>
                      <a className="para-link" href={item.readmore}>
                        <img
                          className="news-image"
                          src={item?.aimage}
                          alt="search-result"
                        />
                      </a>
                      <a className="para-link" href={item.readmore}>
                        <h1 className="categories-topic">{item?.topic}</h1>
                      </a>
                      <p className="categories-para">
                        <a href={item?.link1}>{item?.link1n}</a> /{" "}
                        <a href={item?.link2}>{item?.link2n}</a> /{" "}
                        <a href={item?.link3}>{item?.link3n}</a>
                      </p>
                      <p className="categories-para">{item?.para}</p>
                      <p className="categories-para">
                        <Link to={`/${item.readmore}/`}>Read More »</Link>
                      </p>
                    </div>
                    <div
                      style={{
                        height: "3px",
                        backgroundColor: "#F5F5F5",
                        width: "100%",
                      }}
                    ></div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  height: "2px",
                  backgroundColor: "#F5F5F5",
                  width: "100%",
                }}
              ></div>
            </div>
          </div>
          <br />

          <div
            style={{
              paddingTop: "10px",
              paddingBottom: "10px",
              backgroundColor: totalPages > 1 ? "#fff" : "transparent",
              width: "100%",
            }}
          >
            <center>
              {totalPages > 1 && (
                <div>
                  <a
                    className={`link-button-search ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                    onClick={
                      currentPage !== 1 ? handlePreviousClick : undefined
                    }
                  >
                    ← Previous
                  </a>
                  <span
                    className="page-info"
                    style={{ marginLeft: "10%", marginRight: "10%" }}
                  >
                    Page {currentPage} of {totalPages}
                  </span>
                  <a
                    className={`link-button-search ${
                      currentPage === totalPages ? "disabled" : ""
                    }`}
                    onClick={
                      currentPage !== totalPages ? handleNextClick : undefined
                    }
                  >
                    Next →
                  </a>
                </div>
              )}
            </center>
          </div>
          <br />
          <div
            style={{
              marginLeft: "10px",
              marginRight: "10px",
              marginBottom: "30px",
            }}
          >
            <br />
            <RigthtPage />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default SearchResult;
